.project-card {
  display: flex;
  width: 30vw;
  height: auto;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(33, 28, 28, 0.85);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.1s;
  margin: 2rem;
}

.custom-btncome {
  padding: 2rem;
  color: #211c1c;
  font-weight: 100;
  font-size: 2rem;
  justify-contact: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: left;
}


.subtitle-box {
  margin: auto;
  width: 75%;
}
@media screen and (max-width: 768px) {
  .subtitle-box {
    width: 100%;
  }
}
 
.projectDiv {
  display: flex; 
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
} 


.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 768px) {
  .projectDiv {
    grid-template-columns: 1fr;
  }

  .project-card {
    width: 40%;
    margin: 1 auto;
  }
}

.card-body {
  padding: 0;
}

@media screen and (max-width: 768px) {
  .project-card {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.project-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.project-description {
  margin-top: 10px;
  font-weight: 200;
  font-size: 1rem;
  color: #e3e4e2;
  text-align: left;
  line-height: 1.2;
}

.project-image {
  width: auto;
  height: 350px;
  border-radius: 0.5rem;
  object-fit: cover;
}

.project-card {
  margin-top: 0.25rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #8aff85;
} 

.project-section {
  height: auto;
  background-color: #282222;
  padding: 1rem;
  margin: 20px;
  border: 1px solid #8aff85;
  border-radius: 10px;
}
