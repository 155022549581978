.footer-div {
  display: grid;
  place-content: center;
  min-width: auto;
  height: auto;
  background-color: #282222;
  margin: 20px 10px 20px 10px;
  padding: 1rem;
  bottom: 0;
  justify-content: center;
  text-align: start;
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  border: 0.25px solid #8aff85;
  color: #e3e4e2;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .footer-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
}

img.stack  {
  height: auto;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  padding: 0;
}



.right-footer {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  .right-footer {
    display: flex;
    flex-direction: column;
  }
}

.footer-profile-text {
  width: 100%;
  font-size: clamp(1.5rem, 5vw, 1rem);
  font-weight: 200;
  color: #e3e4e2;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  text-align: left;
  width: 25%;
  margin-right: auto;
  border-radius: 16px;
}
@media screen and (max-width: 768px) {
  .left {
    width: 100%;
    padding: 1rem;
    margin: 0;
  }
}

.separator {
  display: flex;
  height: 0.2px;
  width: auto;
  background-color: #8aff85;
  background-image: linear-gradient(225deg, #8aff85 0%, #d0ff7d 100%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25px;
}

.separator2 {
  display: flex;
  height: 0.2px;
  width: auto;
  background-color: #8aff85;
  background-image: linear-gradient(225deg, #8aff85 0%, #d0ff7d 100%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25px;
}

.info-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.footer-container {
  color: #e3e4e2;
  text-decoration: none;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0.5rem;
  font-weight: 200;
}

@media screen and (max-width: 768px) {
  .footer-menu {
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    width: 100%;
  }
}

.footer-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-tems: center;
  transform-text: uppercase;
  padding: 1rem;
  width: 40%;
  min-height: 350px;
}

@media screen and (max-width: 768px) {
  .footer-col {
    width: 75%;
    padding: 2rem;
    min-height: auto;
  }
}

.footer-nav {
  color: #8aff85;
  font-weight: 700;
  letter-spacing: -2px;
  text-transform: uppercase;
}

.footer-title {
  padding-top: 1rem;
  font-weight: 800;
  letter-spacing: -8px;
  font-size: 8vw;
  color: #8aff85;
  text-align: center;
  margin: 1rem;
}

.footer-item {
  text-transform: uppercase;
  font-size: 1.75rem;
  margin-top: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .footer-title {
    font-size: 4.05rem;
    letter-spacing: -4px;
    flex-wrap: wrap;
    margin: 0;
    justify-content: start;
  }
}
