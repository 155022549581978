.whoops {
  font-size: 8rem;
  font-weight: 700;
}

.cono {
  font-weight: 200;
  text-align: left;
  margin: auto;
}
