.contact-parent {
  display: flex;
  align-items: center;
  height: auto;
  background: transparent;
}

.container {
  border: 1px solid #8aff85;
  border-radius: 10px;
}

.contact-text {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 200;
  margin: 0;
  color: #e3e4e2;
}

.contact-info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.contact-picture {
  grid-area: picture;
  text-align: right;
  padding: 1rem;
}

.contact-picture img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 10rem;
}

.contact-section {
  padding: 1rem;
  height: auto;
  display: flex;
  justify-content: Space-between;
  flex-direction: column;
}

.contact-title {
  font-size: clamp(8rem, 50vw, 10rem);
  letter-spacing: -4px;
  padding: 1rem;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .contact-parent {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "info"
      "picture";
  }

  .contact-picture {
    text-align: center;
  }

  .contact-section {
    padding: 1rem;
  }

  .contact-title {
    font-size: 3.25rem;
    letter-spacing: -4px;
    padding: 0;
    margin: 0;
  }
}
