.about-text {
  font-size: 1.5rem;
  color: #211c1c;
  font-weight: 200;
  text-align: left;
  width: 100%;
}

.text-bento {
  color: #84ff85;
  font-family: Ubuntu;
  font-weight: 900;
}

.about-container {
  padding: 2rem;
  border-radius: 2rem;
}

.row-about {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
}

.about-sub {
  font-size: 2rem;
  color: #000;
  padding: 2rem;
  margin-left: auto;
  font-weight: bolder;
  text-align: center;
}

.listTitle,
.list-item {
  font-size: 2rem;
  color: #fff;
}

li,
ul {
  list-style: none;
}

.about-pic {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.about-row {
  max-width: 650px;
  display: inline;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 2rem;
  color: #000;
  text-align: left;
  font-weight: 500;
  border-radius: 1rem;
}

.about-row strong {
  font-weight: bold;
  display: inline;
}

@media screen and (max-width: 768px) {
  .about-row {
    font-size: 1rem;
  }
}

.about-list {
  font-size: 1.5rem;
}

.about {
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .about-container {
    padding: 1rem;
    margin: 0;
  }

  .about-text {
    font-size: 1rem;
    font-weight: 200;
    width: 100%;
  }

  .div22 {
    max-width: 100%;
    min-height: 250px;
    border-radius: 1rem;
    border: 1px solid #8aff85;
  }
}

.parent-about {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-top: 2rem;
  padding: 4rem;
}

.div11 {
  display: flex;
  flex-direction: column;
  justify-content: evenly;
  grid-area: 1 / 1 / 4 / 5;
  overflow: hidden;
  border-radius: 1rem;
  padding: 1rem;
  border: 1px solid #8aff85;
  line: 1.5;
  background-blend-mode: soft-light, screen;
}

.div22 {
  display: flex;
  justify-content: center;
  grid-area: 1 / 5 / 4 / 7;
  border-radius: 1rem;
  padding: 1rem;
  background-image: url("./../../assets/images/about/profile.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.div33 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-area: 4 / 4 / 6 / 7;
  border: 1px solid #8aff85;
  border-radius: 1rem;
  padding: 1rem;
  transition: transform 0.1s;
}

.div44 {
  grid-area: 6 / 4 / 7 / 7;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8aff85;
  padding: 1rem;
  border-radius: 1rem;
  transition: transform 0.1s;
}

.div2:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.2);
}

.div4:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.2);
}

.div5 {
  grid-area: 4 / 1 / 7 / 4;
  border: 1px solid #8aff85;
  border-radius: 1rem;
  padding: 2rem;
  color: #211c1c;
  transition: transform 0.1s;
}

@media (max-width: 768px) {
  .parent-about {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .div1,
  .div2,
  .div3,
  .div4,
  .div5 {
    width: 100%;
    height: auto;
  }

  .div1,
  .div2,
  .div3,
  .div4,
  .div5 {
    grid-area: auto;
  }
}
