.list-item {
  list-style: none;
  margin: 0;
  padding: 0;
  width: auto;
  margin-right: 1rem;
}

.link-container {
  height: 35px;
  width: auto;
  color: #84ff85;
  text-decoration: none;
}

.menuItem-container {
  padding: 0;
  display: grid;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  width: auto;
  height: 32px;
  overflow: hidden;
  color: #e3e4e2;
  font-weight: 300;
}

.primary {
  font-size: 1.5rem;
  color: #e3e4e2;
}

.secondary {
  font-size: 1.5rem;
  color: #e3e4e2;
}
