.navbar-title {
  font-size: 2rem;
  font-weight: 900;
  margin-left: 1rem;
  text-transform: uppercase;
  color: #e3e4e2;
}
@media screen and (max-width: 768px) {
  .navbar-title {
    font-size: 1.15rem;
    font-weight: 900;
    margin: 0;
  }
}

.navbar-collapse {
  flex-grow: 0;
}

.logo-owl {
  height: 1.25rem;
}

.icon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  display: none;
}

.header {
  
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;

  
  color: #e3e4e2;
  top: 0;
  transition: ease 0.4s;
  &--scrolled {
    height: 40px;
  }
}

.social-icons {
  display: none;
  margin-left: auto;
  padding: 1rem;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .list-item {
    display: flex;
    flex-direction: column;
  }
  .header {
    padding: 1rem;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .logo {
    padding: 0;
    margin: 0;
  }
}

.home-link p {
  font-weight: 900;
}

.link p {
  font-weight: 300;
}
.navbar-toggler-icon {
  background-image: none;
}
.navbar-toggler {
  background-color: linear-gradient(225deg, #8aff85 0%, #d0ff7d 100%);
}
