/* bg: #282222; */

p {
  color: #aeb3aa;
  font-family: Raleway;
  font-weight: 500;
}

a {
  color: #84ff85;
  font-family: Raleway;
  
}

h1, h2, h3, h4, h5 {
  color: #84ff85;
  font-family: Ubuntu;
  font-weight: 900;
}

@font-face {
  font-family: "inter";
}

.container {
  display: flex;
  background: rgb(40, 34, 34);
  background: radial-gradient(
    circle,
    rgba(40, 34, 34, 1) 23%,
    rgba(62, 58, 56, 1) 70%, opacity: 1;
  );
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

#home {
  position: relative;
  overflow: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}



#root,
main {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: #282222;
  background-image: url("./assets/images/bg/contact.svg");
}

.top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.content {
  margin-top: [header-height];
}

.custom-btn {
  background-color: #8aff85;
  background-image: linear-gradient(225deg, #8aff85 0%, #d0ff7d 100%);
  color: #211c11;
  font-size: clamp(1rem, 4vw, 1.5rem);
  font-weight: 700;
  padding: 1rem;
  border: #8aff85;
  background-image: linear-gradient(225deg, #8aff85 0%, #d0ff7d 100%) 3px solid;
  border-radius: 15px;
  text-align: center;
  width: fit-content;
  text-transform: uppercase;
  width: 100%;
  margin: 0rem;
  justify-content: center;
  letter-spacing: 0px;
  text-decoration: none;
  transition: transform 0.1s;
}

.custom-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.2);
}



.section-title {
  font-weight: 900;
  font-size: 7rem;
  text-transform: uppercase;
  color: #8aff85;
  padding: 1rem;
  overflow: hidden;
  text-align: left;
}

.contact-title {
  font-weight: ultra-black;
  text-transform: uppercase;
  font-size: 13rem;
  letter-spacing: -8px;
  color: #e3e4e2;
  padding: 1rem;
  overflow: hidden;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .section-title {
    letter-spacing: -4px;
    font-size: 5.5rem;
  }
}

.white-bg {
  background: rgb(0, 212, 255);
  color: #211c1c;
  background: radial-gradient(
    circle,
    rgba(0, 212, 255, 1) 0%,
    rgba(20, 121, 9, 1) 52%,
    rgba(2, 0, 36, 1) 89%
  );
  border-radius: 0.5rem;
  width: 100%;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 3.5rem;
    letter-spacing: -4px;
  }
}

.stack {
  color: #8aff85;
  height: 4rem;
  width: 4rem;
}
