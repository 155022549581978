ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 1rem;
}

li {
  position: relative;
}

span {
  opacity: 0;
  color: #8aff85;
}

.reveal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #282222;
  transform: scaleX(0);
  transform-origin: left;
}
.title-sub {
  display: grid;
  place-content: center;
}
