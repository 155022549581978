.hero-section {
  display: flex;
  height: auto;
  margin-top: 2rem;
  color: #84ff85;
}

@media (max-width: 768px) {
  .hero-section {
    height: auto;
  }
}




.hero {
  background: rgb(40,34,34);
  background: radial-gradient(circle, rgba(40,34,34,1) 23%, rgba(62,58,56,1) 60%);
  display: grid;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 20px 20px 20px 20px;  
  width: 100dvw;
  shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .hero {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
}

.div1 { grid-area: 1 / 1 / 6 / 9; }
.div2 { grid-area: 1 / 9 / 6 / 12; }
.div3 { grid-area: 6 / 1 / 12 / 12 }

.name {
  color: #8aff85;
  font-size: 20rem;
  font-weight: 900;
  margin: 0;
  padding: 0;
  text-align: left;
  letter-spacing: -1rem;
  line-height: 0.8;
}

@media (max-width: 768px) {
  .name{
    margin-top: 1rem;
    font-size: 8rem;
    letter-spacing: -.5rem;
    text-align: center;
  }
}

.last-name {
  color: #8aff85;
  font-size: 10rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-align: left;
  letter-spacing: -1rem;
  line-height: 0.8;
}

@media (max-width: 768px) {
  .last-name {
    font-size: 4.6rem;
    letter-spacing: -.7rem;
    text-align: center;
  }
}

.hero-image {
  height: 300px;
  width: auto;
  border-radius: 2rem;
  margin: auto;
  padding: 1rem;
}

.links a {
text-decoration: none;
color: #e3e4e2;
}

.right {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 30%;
  justify-content: space-between;
  
}

.links {
  text-decoration: none;
  display: flex;
  width: 30%;
  color: #8aff85;
}

.row {
  justify-content: space-between;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .right, .links {
    width: 100%;
    padding: 1rem;
    margin: auto;
    text-align: center;
  }
}









