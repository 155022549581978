.cta {
  display: flex;
  width: 100dvw;
  height: 90vh;
  justify-content: space-between;
  padding: 1rem;
}



.cta-title {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: clamp(8rem, 50vw, 10rem);
  letter-spacing: -11px;
  color: #e3e4e2;
  padding: 1rem;
  overflow: hidden;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 768px) {
  .cta-title {
    letter-spacing: -4px;
    font-size: 4rem;
  }
}

.cta-welcome {
  padding: 1rem;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  color: #b2b3b1;
}
